<template>
  <cs-segment class="cs-page-error">
    <cs-container>
      <div class="row text">
        <div class="col">
          <h1>{{ $t('error.title') }}</h1>
          <p>{{ errorMsg }}</p>
        </div>
      </div>
    </cs-container>
  </cs-segment>
</template>

<script>
export default {
  name: 'cs-page-error',
  computed :{
    errorMsg () {
      let code, msg
      if (this.$store.getters.siteError) {
        code = this.$store.state.status.site
      } else {
        code = this.$store.state.status.page
      }
      if (code) {
        msg = 'error.msg' + code
      } else {
        code = ''
      }
      if (this.$te(msg)) {
        return this.$t(msg, { code: code})
      } else {
        return this.$t('error.msg', { code: code})
      }
    }
  }
}
</script>

<style lang="sass">
// .cs-page-error
</style>
